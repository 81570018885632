
import { mapState } from 'vuex';
import * as Cesium from 'cesium';
import Graph from '@/components/common/Graph';
import ItemMeasurementsPointsEditor from '@/components/inspections/ItemMeasurementsPointsEditor';

export default {
  name: "InspectionsItemMeasurementsItem",
  components: {
    Graph,
    ItemMeasurementsPointsEditor,
  },
  props: {
    measurement: Object,
    isUploaded: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedTagId: '',
    graphConfig: null,
    newDescription: '',
  }),
  computed: {
    ...mapState('users', [
      'getUsersResult',

      'getCurrentProfileResult',
    ]),
    ...mapState('inspections__new', [ 'currentInspection' ]),
    ...mapState('tags', [
      'getTagsResult',
      'getTagsIsLoading',
      'getTagsError',
    ]),
    ...mapState('cesium', [
      'viewer',
      'selectedMeasurementId',
      'isSelectedMeasurementEditable'
    ]),
    ...mapState('measurements', [
      'defaultTagId',
    ]),
    currentTag() {
      if (this.measurement?.tag) {
        return this.getTagsResult.find(tag => tag.id === this.measurement.tag);
      } else if (this.defaultTagId && !this.isUploaded) {
        return this.getTagsResult.find(tag => tag.id === this.defaultTagId);
      } else {
        return null;
      }
    },
    user() {
      const user = this.getUsersResult?.find(user => user.id === this.measurement.user);
      if (!user) {
        return null;
      }
      return user.first_name + ' ' + user.last_name;
    },
    isSelected() {
      return this.selectedMeasurementId === this.measurement.id;
    },
    isEditable() {
      return this.isSelected && this.isSelectedMeasurementEditable;
    },
    visualState() {
      if (this.isEditable) {
        return 'editable';
      } else if (this.isSelected) {
        return 'selected';
      } else {
        return 'default';
      }
    },
  },
  methods: {
    upload() {
      const data = new FormData();
      data.set('positions', JSON.stringify(this.measurement.positions));
      data.set('shape', this.measurement.type);
      data.set('inspection', this.currentInspection.id);
      if (this.newDescription) {
        data.set('content', this.newDescription);
      }
      if (this.selectedTagId) {
        data.set('tag', this.selectedTagId);
      } else if (this.measurement.tag) {
        data.set('tag', this.measurement.tag.id);
      }
      this.$store.dispatch('measurements/addMeasurementsItem', {
        data,
        onSuccess: () => {
          this.$store.dispatch('measurements/getMeasurements', { inspection: this.currentInspection.id });
          this.$store.commit('cesium/removeEntity', this.measurement.id);
        },
      });
    },
    edit() {
      if (!this.isSelected) {
        this.toggleItem();
      }
      this.$store.dispatch('cesium/setIsSelectedMeasurementEditable', true);
    },
    remove() {
      if (this.isUploaded) {
        this.$store.dispatch('measurements/removeMeasurementsItem', {
          id: this.measurement.id,
          onSuccess: () => {
            this.$store.dispatch('measurements/getMeasurements', { inspection: this.currentInspection.id })
            this.$store.commit('cesium/removeEntity', this.measurement.id);
          },
        });
      } else {
        this.$store.commit('cesium/removeEntity', this.measurement.id);
      }
    },
    toggleItem() {
      this.$store.dispatch('cesium/toggleMeasurementItem', this.measurement);
    },
    calculateGraphData() {
      const entity = this.viewer.entities.getById(this.measurement.id);
      if (!entity) {
        return null;
      }
      const line = new Cesium.EllipsoidGeodesic(
        new Cesium.Cartographic.fromCartesian(entity.polyline.positions._value[0]),
        new Cesium.Cartographic.fromCartesian(entity.polyline.positions._value[1]),
      );

      var fractionalPointList = []
      const steps = 500
      for (var i = 0; i < steps; i++) {
        var fraction = i / steps
        var fractionalPoint = line.interpolateUsingFraction(fraction, new Cesium.Cartographic());
        fractionalPointList.push(fractionalPoint)
      }
      const heightProfile = [];
      const heightProfileLabels = [];
      Cesium.sampleTerrainMostDetailed(this.viewer.terrainProvider, fractionalPointList)
        .then(result => {
          for (var i = 0; i < result.length; i++) {
            var height = result[i].height - result[0].height;
            heightProfile.push(height);
            heightProfileLabels.push(i);
          }
          const data = {
            labels: heightProfileLabels,
            datasets: [{
                label: 'Height profile',
                backgroundColor: 'rgb(144, 202, 249)',
                borderColor: 'rgb(144, 202, 249)',
                data: heightProfile,
            }],
          };
          const config = {
            type: 'line',
            data,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                    display: false,
                },
              },
              scales: {
                x: {
                  display: false,
                },
              },
              elements: {
                point: {
                  radius: 1,
                },
              },
            }
          };
          this.graphConfig = config;
        });
    },
  },
  watch: {
    defaultTagId: {
      handler() {
        this.selectedTagId = this.defaultTagId || '';
      },
      immediate: true,
    },
    isSelected: {
      immediate: true,
      handler(isSelected) {
        if (isSelected) {
          if (this.$el) {
            this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            this.$nextTick(() => {
              this.$el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          }
        }
        if (isSelected && this.measurement.type === 'height' && !this.graphConfig) {
          this.calculateGraphData();
          return;
        }
      },
    },
    visualState() {
      this.$store.commit('cesium/colorizeMeasurementEntity', { measurementData: this.measurement, visualState: this.visualState });
    },
  },
}
