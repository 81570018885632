
import { mapState } from 'vuex';

export default {
  name: "InspectionsItemIssuesListItemLine",
  props: {
    issue: Object,
  },
  data: () => ({
    scrollAttempts: 0,
    isIntersectionFormVisible: false,
    showData: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',
    ]),
    ...mapState('cesium', [
      'lastActiveIssueId',

      'damagedIssue',
      'damagedIssuePosition',
    ]),
    issueDataFormatted() {
      if (!this.issue.data) {
        return null;
      }
      let cleanedData = this.issue.data;
      try {
        if (typeof cleanedData !== 'object' || cleanedData === null) {
          throw new Error("Parsed data is not an object");
        }
        // Filter out properties where the value is null
        if (cleanedData.issue && Array.isArray(cleanedData.issue)) {
          cleanedData.issue = cleanedData.issue.filter(item => item.Value !== null && item.Value !== 'null' && item.Value !== '' && item.Value !== 'undefined' && item.Value !== 'None');
        }
        const filteredData = Object.fromEntries(
          Object.entries(cleanedData)
        );
        return JSON.stringify(filteredData.issue, null, 2); // Indent with 2 spaces
      } catch (e) {
        console.error("Error parsing issue data:", e);
        return null;
      }
    },
    bamIssueType() {
      try {
        const formattedData = JSON.parse(this.issueDataFormatted);
        const schadevormObject = formattedData.find(item => item.Name === 'Schadevorm');
        return schadevormObject ? schadevormObject.Value : 'Unknown';
      } catch (e) {
        return 'Unknown';
      }
    },
    error() {
      if (!this.issue.model_intersection_center || this.issue.model_intersection_center === 'null') {
        return 'No intersection point';
      }
      return null;
    },
  },
  methods: {
    toggleCategory() {
      this.$emit('toggle-category', this.issue.issue_type);
    },
    flyToIssue() {
      if (this.lastActiveIssueId === this.issue.id) {
        this.$store.commit('cesium/resetLastIssue');
        if (this.$store.state.cesium.savedCameraPosition) {
          this.$store.dispatch('cesium/cameraFlyTo', this.$store.state.cesium.savedCameraPosition);
          this.$store.state.cesium.savedCameraPosition = null;
        }
      } else {
        this.$store.state.cesium.lastActiveIssueId = this.issue.id;
        this.$store.dispatch('cesium/cameraFlyToIssue', { issue: this.issue });
      }
    },
    reqursiveScrollToSelected() {
      const ref = this.$refs.selected;
      if (!ref) {
        if (this.scrollAttempts < 10) {
          this.$nextTick(this.reqursiveScrollToSelected);
          this.scrollAttempts++;
        }
        return;
      }
      ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.scrollAttempts = 0;
    },
    toggleIntersectionForm() {
      this.isIntersectionFormVisible = !this.isIntersectionFormVisible;
    },
    toggleShowData() {
      this.showData = !this.showData;
    },
    saveIntersectionPosition() {
      const payload = new FormData();
      payload.append('model_intersection_center', JSON.stringify([ this.damagedIssuePosition.x, this.damagedIssuePosition.y, this.damagedIssuePosition.z ]));
      this.$store.dispatch('inspections__new/updatePhotoIssues', {
        id: this.issue.id,
        payload,
        onSuccess: () => {
          this.$store.commit('cesium/eraseDamagedIssue');
          this.$store.state.cesium.damagedIssuePosition = null;
          this.$store.state.cesium.damagedIssue = null;
          this.$store.dispatch('inspections__new/getInspectionIssues', { id: this.currentInspection.id });
        },
      });
      this.toggleIntersectionForm();
    },
  },
  watch: {
    lastActiveIssueId: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.issue.id === newVal) {
          this.reqursiveScrollToSelected();
        }
      },
    },
    isIntersectionFormVisible(newVal) {
      if (newVal) {
        this.$store.state.cesium.damagedIssue = this.issue;
      }
    },
    damagedIssue(newVal) {
      if (newVal && newVal.id !== this.issue.id) {
        this.isIntersectionFormVisible = false;
        return;
      }
    },
    damagedIssuePosition(newVal) {
      if (this.damagedIssue?.id !== this.issue.id) {
        return;
      }
      if (newVal) {
        this.$store.commit('cesium/drawOrUpdateDamagedIssue');
      } else {
        this.$store.commit('cesium/eraseDamagedIssue');
      }
    },
  },
}
