
import { mapState } from 'vuex';
import InspectionsItemNotesListItem from './ItemNotesListItem'
import InspectionsItemNotesAddOrEdit from './ItemNotesAddOrEdit'
import Loader from '../Loader'

export default {
  name: "InspectionsItemNotes",
  components: {
    InspectionsItemNotesListItem,
    InspectionsItemNotesAddOrEdit,
    Loader,
  },
  data: () => ({
    mode: 'list',
    itemToEdit: null,
  }),
  computed: {
    ...mapState('users', [
      'getUsersResult',
      'getUsersIsLoading',

      'getCurrentProfileIsLoading',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionNotesResult',
      'getInspectionNotesIsLoading',
      'getInspectionNotesError',

      'removeInspectionNotesItemResult',
      'removeInspectionNotesItemIsLoading',
      'removeInspectionNotesItemError',
    ]),
    isLoading() {
      return this.getInspectionNotesIsLoading ||
        this.getUsersIsLoading ||
        this.getCurrentProfileIsLoading ||
        this.removeInspectionNotesItemIsLoading;
    },
  },
  methods: {
    getPDF() {
      this.$store.dispatch('inspections__new/exportInspectionNotes', { id: this.currentInspection.id });
    },
    add() {
      this.mode = 'add';
    },
    backHandler() {
      this.mode = 'list';
      this.itemToEdit = null;
    },
  },
  beforeMount() {
    if (!this.getUsersResult && !this.getUsersIsLoading) {
      this.$store.dispatch('users/getUsers');
    }
    this.$store.dispatch('cesium/showNotes');
  },
}
