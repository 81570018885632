
// import Chart from 'chart.js/auto';
import { Chart, registerables } from 'chart.js';

export default {
  name: 'Graph',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => ({
    graph: null,
    defaultConfig: {},
  }),
  methods: {
    initGraph() {
      if (!this.$refs.canvas) {
        console.error('Graph component is not mounted');
        return;
      }
      const config = { ...this.defaultConfig, ...this.config };
      this.graph = new Chart(
        this.$refs.canvas,
        config,
      );
    }
  },
  beforeMount() {
    Chart.register(
      ...registerables,
    );
  },
  mounted() {
    this.initGraph();
  },
  beforeUnmount() {
    this.graph && this.graph.destroy();
  },
}
