
import { mapState } from 'vuex';
import Loader from '../Loader'
import InspectionsItemPhotosList from './ItemPhotosList.vue';

export default {
  name: "InspectionsItemCameras",
  components: {
    Loader,
    InspectionsItemPhotosList,
  },
  data: () => ({
    isImgLoading: false,
    isClosestPhotosCalculationRequested: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionPhotosIsLoading',
    ]),
    ...mapState('cesium', [
      'activeCameraEntity',
      'closestPhotos',
    ]),
    getInspectionPhotosResult() {
      return this.$store.getters['inspections__new/photosWithCesiumData'];
    },
    photo() {
      if (!this.activeCameraEntity || !this.getInspectionPhotosResult) {
        return null;
      }
      const currentPhotoId = parseInt(this.activeCameraEntity.id.split('-')[1], 10);
      return this.getInspectionPhotosResult.find(x => x.id === currentPhotoId) || null;
    },
    isLoading() {
      return this.getInspectionPhotosIsLoading || this.isImgLoading;
    },
  },
  methods: {
    back() {
      this.$store.state.cesium.activeCameraEntity = null;
      if (this.$store.state.cesium.savedCameraPosition) {
        this.$store.dispatch('cesium/cameraFlyTo', this.$store.state.cesium.savedCameraPosition);
        this.$store.state.cesium.savedCameraPosition = null;
      }
    },
    getClosestPhotos() {
      if (!this.isClosestPhotosCalculationRequested && this.activeCameraEntity?.position) {
        this.isClosestPhotosCalculationRequested = true;
        this.$store.state.cesium.closestPhotos = [];
        this.$store.dispatch('cesium/getClosestPhotos', { position: this.activeCameraEntity.position });
      }
    },
    photoLoadHandler() {
      this.isImgLoading = false;
    },
    toggleMiniViewer() {
      this.$store.state.cesium.currentView = this.$store.state.cesium.currentView === 'mini' ? null : 'mini';
    },
    setActiveCamera(camera) {
      this.$store.dispatch('cesium/flyToCamera', { photoId: camera.id });
    },
  },
  beforeMount() {
    const id = this.currentInspection.id;
    if (!this.getInspectionPhotosIsLoading && (!this.getInspectionPhotosResult || (this.getInspectionPhotosResult.length > 0 && this.getInspectionPhotosResult[0].inspection !== id))) {
      this.$store.dispatch('inspections__new/getInspectionPhotos', {
        id,
        onSuccess: () => {
          this.$store.dispatch('cesium/showCameras');
        },
      });
    } else {
      this.$store.dispatch('cesium/showCameras');
    }
  },
  watch: {
    photo(newVal) {
      if (newVal) {
        this.isImgLoading = true;
      } else {
        this.isImgLoading = false;
      }
    },
    activeCameraEntity: {
      handler() {
        this.isClosestPhotosCalculationRequested = false;
        this.$store.state.cesium.closestPhotos = [];
      },
      immediate: true,
    },
  },
}
