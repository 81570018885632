
import { mapState } from 'vuex';
import CommonIntersectionObserver from '@/components/common/IntersectionObserver.vue';
import InspectionsItemIssuesListItemLine from './ItemIssuesListItemLine.vue';

export default {
  name: "InspectionsItemIssuesListItem",
  components: {
    CommonIntersectionObserver,
    InspectionsItemIssuesListItemLine,
  },
  props: {
    photoId: [ String, Number ],
    issues: Array,
    extendedPhotos: Array,
  },
  data: () => ({
    isVisible: false,
  }),
  computed: {
    ...mapState('cesium', [
      'lastActiveIssueId',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionPhotosResult',

      'getInspectionIssuesResult',
    ]),
    isExtended() {
      return this.extendedPhotos.indexOf(this.photoId) > -1;
    },
    hasErrors() {
      return this.issues.some(issue => !issue.model_intersection_center || issue.model_intersection_center === 'null');
    },
    photo() {
      return this.$store.getters['inspections__new/photosWithCesiumData'].find(photo => photo.id == this.photoId);
    },
  },
  methods: {
    toggleGroup() {
      this.$emit('toggle-group', this.photoId);
    },
    toggleCategory(category) {
      this.$emit('toggle-category', category);
    },
    show() {
      this.isVisible = true;
    },
    flyToPhoto() {
      if (!this.photo) {
        return;
      }
      this.$store.dispatch('cesium/cameraFlyTo', {
        position: this.photo.position,
        type: 'heading-pitch-roll',
        data: this.photo.hpr,
      });
    },
    idClickHandler() {
      if (!this.isExtended) {
        this.flyToPhoto();
      }
      this.toggleGroup();
    },
    openAssignModal() {
        this.$store.dispatch('modals/show', {
          name: 'AssignIssuesToPhoto',
          props: {
            unassignedIssues: this.issues,
            allIssues: this.getInspectionIssuesResult,
            photos: this.getInspectionPhotosResult,
            onComplete: () => {
              this.$store.dispatch('inspections__new/getInspectionPhotos', { id: this.currentInspection.id });
              this.$store.dispatch('inspections__new/getInspectionIssues', { id: this.currentInspection.id });
            },
          },
        });
    },
  },
  watch: {
    isExtended(newVal) {
      if (!newVal) {
        this.isVisible = false;
      }
    },
  },
}
