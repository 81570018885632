
import { mapState } from 'vuex';

export default {
  name: 'InspectionsItemImageryLayerControls',
  data: () => ({
    isSliderVisible: false,
    tool: {
      icon: 'assets/media/icons/duotone/Interface/Settings-02.svg',
    },
  }),
  computed: {
    ...mapState('cesium', [
      'imageryLayerAlpha',
    ]),
    imageryLayerAlpha: {
      get() {
        return this.$store.state.cesium.imageryLayerAlpha;
      },
      set(value) {
        console.log('set imageryLayerAlpha', value);
        this.$store.state.cesium.imageryLayerAlpha = value;
        this.$store.state.cesium.imageryLayer.alpha = this.$store.state.cesium.imageryLayerAlpha;
      },
    },
  },
  methods: {
    toggleSkiderVisibility() {
      this.isSliderVisible = !this.isSliderVisible;
    },
  },
}
