
import { mapState } from 'vuex';
import InspectionsItemMeasurementsItem from './ItemMeasurementsItem';

export default {
  name: "InspectionsItemMeasurementsDrafts",
  components: {
    InspectionsItemMeasurementsItem,
  },
  data: () => ({
    isTagGroupVisible: false,
  }),
  computed: {
    ...mapState('users', [
      'getUsersIsLoading',

      'getCurrentProfileIsLoading',
    ]),
    ...mapState('tags', [
      'getTagsResult',
      'getTagsIsLoading',
      'getTagsError',
    ]),
    ...mapState('cesium', [
      'viewer',
      'currentTool',
      'activeShape',
      'activeShapePoints',
      'floatingPoint',
      'uncommitedShapes',
    ]),
    ...mapState('measurements', [
      'getMeasurementsResult',
      'getMeasurementsIsLoading',
      'getMeasurementsError',
    ]),
    uncommitedMeasurements() {
      return this.uncommitedShapes;
    },
    selectedTag() {
      return this.getTagsResult?.find(tag => tag.id === this.defaultTagIdInternal);
    },
    defaultTagIdInternal: {
      get() {
        return this.$store.state.measurements.defaultTagId || '';
      },
      set(value) {
        this.$store.state.measurements.defaultTagId = value;
        this.isTagGroupVisible = false;
      },
    },
  },
  methods: {
    commitMeasurement() {
      this.defaultTagIdInternal = null;
      this.lastShape = null;
    },
    removeMeasurement() {
      this.lastShape = null;
      this.$store.dispatch('cesium/cancelToolAction');
    },
  },
}
