
import { mapState } from 'vuex';
import * as Cesium from 'cesium';

export default {
  name: "InspectionsItemNotesListItem",
  props: {
    note: Object,
  },
  data: () => ({
    isRemoveReady: false,
  }),
  computed: {
    ...mapState('users', [
      'getUsersResult',
      'getUsersIsLoading',
      'getUsersError',
    ]),
    ...mapState('cesium', [
      'viewer',
      'selectedNoteId',
    ]),
    ...mapState('inspections__new', [
      'getInspectionsItemResult',

      'getInspectionNotesResult',
      'getInspectionNotesIsLoading',
      'getInspectionNotesError',

      'removeInspectionNotesItemResult',
      'removeInspectionNotesItemIsLoading',
      'removeInspectionNotesItemError',
    ]),
    isLoading() {
      return this.getInspectionNotesIsLoading || this.getUsersIsLoading;
    },
    user() {
      return this.getUsersResult?.find(x => x.id === this.note.user);
    },
    name() {
      if (!this.user) {
        return '';
      }
      return [this.user.first_name, this.user.last_name].filter(x => !!x).join(' ');
    },
    urgencyClass() {
      const urgency = this.note.urgency.toLowerCase();
      if (urgency === 'low') {
        return 'badge-light-info';
      } else if (urgency === 'medium') {
        return 'badge-light-warning';
      }
      return 'badge-light-danger';
    },
    isSelected() {
      return this.selectedNoteId === 'note-' + this.note.id;
    },
    files() {
      return [
        this.note.file,
        this.note.file2,
        this.note.file3,
        this.note.file4,
        this.note.file5,
        this.note.file6,
      ].filter(x => !!x);
    },
  },
  methods: {
    editHandler() {
      this.$emit('set-item-to-edit', this.note);
    },
    removeHandler() {
      if (this.isRemoveReady && !this.removeInspectionNotesItemIsLoading) {
        this.$store.dispatch('inspections__new/removeInspectionNotesItem', { id: this.note.id, onSuccess: this.successHandler });
        this.isRemoveReady = false;
      } else {
        this.isRemoveReady = true;
      }
    },
    removeCancelHandler() {
      this.isRemoveReady = false;
    },
    successHandler() {
      this.$store.commit('cesium/deleteNote', { id: this.note.id });
      this.$store.dispatch('inspections__new/getInspectionNotes', { id: this.getInspectionsItemResult.id });
    },
    clickHandler() {
      this.$store.dispatch('cesium/toggleNoteItem', { id: 'note-' + this.note.id });
    },
  },
  watch: {
    isSelected: {
      immediate: true,
      handler(isSelected) {
        if (isSelected) {
          if (this.$el) {
            this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            this.$nextTick(() => {
              this.$el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          }
        }
        const params = {
          fillColor: isSelected ? Cesium.Color.BLACK : Cesium.Color.WHITE,
          backgroundColor: isSelected ? new Cesium.Color(0, 1, 0, 0.8) : new Cesium.Color(0.165, 0.165, 0.165, 0.8),
        }
        const label = this.viewer.entities.getById('note-' + this.note.id).label;
        label.fillColor = params.fillColor;
        label.backgroundColor = params.backgroundColor;
      },
    },
  },
}
