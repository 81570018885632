
import { mapState } from 'vuex';
import CesiumFunctions from '@/core/cesium/CesiumFunctions';
import InspectionsItemMeasurementsItem from './ItemMeasurementsItem';

export default {
  name: "InspectionsItemMeasurementsUploaded",
  components: {
    InspectionsItemMeasurementsItem,
  },
  data: () => ({
    activeTags: [],
  }),
  computed: {
    ...mapState('users', [
      'getUsersIsLoading',

      'getCurrentProfileIsLoading',
    ]),
    ...mapState('cesium', [
      'viewer',
    ]),
    ...mapState('tags', [
      'getTagsResult',
      'getTagsIsLoading',
      'getTagsError',
    ]),
    ...mapState('measurements', [
      'getMeasurementsResult',
      'getMeasurementsIsLoading',
      'getMeasurementsError',
    ]),
    usedTags() {
      if (!this.getTagsResult) {
        return [];
      }
      let isNullTagNeeded = false;
      const result = this.getTagsResult.filter(tag => {
        return this.getMeasurementsResult.some(measurement => {
          if (!measurement.tag) {
            isNullTagNeeded = true;
            return false;
          }
          return measurement.tag === tag.id;
        });
      });
      if (isNullTagNeeded) {
        result.push({
          id: null,
          name: 'No tag',
          color: '#999',
        });
      }
      return result;
    },
    filteredMeasurements() {
      if (!this.getMeasurementsResult) {
        return [];
      }
      if (this.activeTags.length === 0) {
        return this.getMeasurementsResult;
      }
      const result = this.getMeasurementsResult.filter(x => this.activeTags.indexOf(x.tag) > -1);
      return result;
    },
  },
  methods: {
    toggleTag(tagId) {
      if (this.activeTags.indexOf(tagId) > -1) {
        this.activeTags.splice(this.activeTags.indexOf(tagId), 1);
      } else {
        this.activeTags.push(tagId);
      }
    },
  },
  watch: {
    filteredMeasurements: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        const idsToPreserve = oldVal ? newVal.filter(x => oldVal.some(y => y.id === x.id)).map(x => x.id) : [];
        oldVal && oldVal.forEach(x => {
          if (idsToPreserve.indexOf(x.id) > -1) {
            return;
          }
          this.$store.commit('cesium/hideEntity', x.id);
        });
        newVal.forEach(x => {
          if (idsToPreserve.indexOf(x.id) > -1) {
            return;
          } else if (this.viewer.entities.getById(x.id)) {
            this.$store.commit('cesium/showEntity', x.id);
          } else {
            CesiumFunctions.drawShape({ viewer: this.viewer, positions: x.positions, type: x.shape, id: x.id });
          }
        });
      },
    }
  },
}
