
import { mapState } from 'vuex';
import * as Cesium from 'cesium';
import Loader from '../Loader';
import InspectionsItemIssuesListItem from './ItemIssuesListItem';

export default {
  name: "InspectionsItemIssues",
  components: {
    Loader,
    InspectionsItemIssuesListItem,
  },
  data: () => ({
    // extendedCounter: 0,
    extendedPhotos: [],
    minSize: null,
    maxSize: null,
    minSizeEntered: 0,
    maxSizeEntered: 0,
    sizesApplied: [0, 0],
    sizeUpdateTimeout: null,
    sizeUpdateDelay: 500,
  }),
  computed: {
    ...mapState('cesium', [
      'activeIssuesCategories',
      'lastActiveIssueId',
      'intersectionFixesInspectionId',
      'intersectionFixesCompleted',
    ]),
    ...mapState('users', [
      'getUsersIsLoading',

      'getCurrentProfileIsLoading',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionIssuesResult',
      'getInspectionIssuesIsLoading',
      'getInspectionIssuesError',

      'getInspectionPhotosResult',
      'getInspectionPhotosIsLoading',
      'getInspectionPhotosError',

      'updatePhotoIssuesIsLoading',

      'getInspectionPhotosIsLoading',
    ]),
    isLoading() {
      return this.getInspectionIssuesIsLoading
        || this.updatePhotoIssuesIsLoading
        || this.getInspectionPhotosIsLoading
        || !this.getInspectionPhotosResult
        || !this.getInspectionIssuesResult;
    },
    selectedPhoto() {
      if (this.lastActiveIssueId && this.getInspectionIssuesResult) {
        const result = this.getInspectionIssuesResult.find(x => x.id === this.lastActiveIssueId)?.photo;
        return parseInt(result, 10) || null;
      }
      return null;
    },
    baseItems() {
      if (!this.getInspectionIssuesResult) {
        return [];
      }
      if (this.selectedPhoto) {
        const result = this.getInspectionIssuesResult.filter(x => x.photo === this.selectedPhoto);
        return result;
      }
      return this.getInspectionIssuesResult;
    },
    categories() {
      return this.baseItems.reduce((acc, curr) => {
        if (!acc.find(x => x.code === curr.issue_type)) {
          acc.push({
            code: curr.issue_type,
            color: curr.label_color_code,
            isColorLight: curr.is_label_color_light,
          });
        }
        return acc;
      }, []).sort((a, b) => a.code > b.code ? 1 : -1);
    },
    itemsFiltered() {
      if (this.activeIssuesCategories.length === 0 &&
        this.sizesApplied[0] === this.minSize &&
        this.sizesApplied[1] === this.maxSize) {
        return this.baseItems;
      }
      return this.baseItems.filter(x => {
        if (this.activeIssuesCategories.length > 0 && this.activeIssuesCategories.indexOf(x.issue_type) < 0) {
          return false;
        }
        if ((this.sizesApplied[0] !== null && x.size < this.sizesApplied[0]) || (this.sizesApplied[1] !== null && x.size > this.sizesApplied[1])) {
          return false;
        }
        return true;
      });
    },
    photos() {
      return this.itemsFiltered.reduce((acc, curr) => {
        if (!curr.photo || curr.photo === 'null') {
          return acc;
        }
        if (acc[curr.photo]) {
          acc[curr.photo].push(curr);
        } else {
          acc[curr.photo] = [ curr ];
        }
        return acc;
      }, {});
    },
    photosWithCorruptedIssues() {
      if (!this.getInspectionIssuesResult || !this.$store.getters['inspections__new/photosWithCesiumData']) {
        return [];
      }
      const result = this.getInspectionIssuesResult.reduce((acc, curr) => {
        if (!curr.photo || curr.photo === 'null') {
          return acc;
        }
        if (
          curr.model_intersection_center &&
          curr.model_intersection_center !== 'null' &&
          curr.model_intersection_center !== '{\"x\":3957601.392192846,\"y\":257112.19932568396,\"z\":4978515.481256839}'
        ) {
          return acc;
        }
        const existingPhoto = acc.find(x => x.photo.id == curr.photo);
        if (existingPhoto) {
          existingPhoto.issues.push(curr);
        } else {
          const photo = this.$store.getters['inspections__new/photosWithCesiumData'].find(x => x.id == curr.photo);
          if (!photo) {
            return acc;
          }
          acc.push({
            photo,
            issues: [ curr ],
          });
        }
        return acc;
      }, []);
      // console.log('photosWithCorruptedIssues', result);
      return result;
    },
    photosAmount() {
      return Object.keys(this.photos).length + (this.noPhotoIssues.length > 0 ? 1 : 0);
    },
    isAllExtended() {
      return this.extendedPhotos.length === this.photosAmount;
    },
    noPhotoIssues() {
      return this.itemsFiltered.filter(x => !x.photo || x.photo === 'null');
    },
    isReadyTofix() {
      return !this.intersectionFixesCompleted && this.$store.getters['inspections__new/photosWithCesiumData'] && this.photosWithCorruptedIssues.length > 0 && this.intersectionFixesInspectionId === null;
    },
  },
  methods: {
    toggleCategory(categoryCode) {
      if (this.activeIssuesCategories.indexOf(categoryCode) > -1) {
        this.$store.state.cesium.activeIssuesCategories = this.activeIssuesCategories.filter(x => x !== categoryCode);
      } else {
        this.$store.state.cesium.activeIssuesCategories = [ ...this.activeIssuesCategories, categoryCode ];
      }
    },
    resetCategories() {
      this.$store.state.cesium.activeIssuesCategories = [];
    },
    toggleEtendedMode() {
      if (this.isAllExtended) {
        this.extendedPhotos = [];
      } else {
        const result = Object.keys(this.photos);
        if (this.noPhotoIssues.length > 0) {
          result.push(null);
        }
        this.extendedPhotos = result;
      }
    },
    toggleGroup(photo) {
      const photoIndex = this.extendedPhotos.indexOf(photo);
      if (photoIndex > -1) {
        this.extendedPhotos.splice(photoIndex, 1);
      } else {
        this.extendedPhotos.push(photo);
      }
    },
    resetSelectedPhoto() {
      this.$store.commit('cesium/resetLastIssue');
    },
    resetSizes() {
      this.minSizeEntered = this.minSize;
      this.maxSizeEntered = this.maxSize;
    },
    fixModelIntersections() {
      console.log('fixModelIntersections', this.photosWithCorruptedIssues.length);
      const photos = this.photosWithCorruptedIssues;

      this.$store.state.cesium.intersectionFixesInspectionId = this.currentInspection.id;
      this.$store.state.cesium.intersectionFixesProgress = 0;

      const fixes = [];
      let isLoading = true;
      let counter = 0;
      const tilesets = this.$store.state.cesium.viewer.scene.primitives._primitives.filter((primitive) => primitive instanceof Cesium.Cesium3DTileset);

      const loadProgressHandler = (pending, processing) => {
        if (pending === 0 && processing === 0 && tilesets.reduce((acc, curr) => acc && curr.tilesLoaded, true)) {
          isLoading = false;
        } else {
          isLoading = true;
        }
      };

      tilesets.forEach((tileset) => {
        tileset.loadProgress.addEventListener(loadProgressHandler);
      });

      const fixPhoto = () => {
        const photo = photos[counter].photo;
        const issues = photos[counter].issues;
        const intersections = [];
        
        console.log('fixing photo', photo.id);

        const completeAndReset = () => {
          console.log('completeAndReset');
          fixes.push({ photo: photo.id, intersections });
          this.$store.dispatch('cesium/resetView');
          this.$store.state.cesium.intersectionFixesProgress = (counter + 1) / photos.length;
          if (counter < photos.length - 1) {
            counter++;
            setTimeout(fixPhoto, 1000);
          } else {
            console.log('all photos fixed');
            setTimeout(() => {
              this.$store.state.cesium.intersectionFixesInspectionId = null;
              this.$store.state.cesium.intersectionFixesProgress = 0;
              this.$store.state.cesium.intersectionFixesCompleted = true;
              this.$store.dispatch('inspections__new/getInspectionIssues', { id: this.currentInspection.id });
            }, 1000);
            // console.log('vizualising results');
            // for (let i = 0; i < fixes.length; i++) {
            //   for (let j = 0; j < fixes[i].intersections.length; j++) {
            //     this.$store.state.cesium.viewer.entities.add({
            //       position: fixes[i].intersections[j].intersection,
            //       point: {
            //         color: new Cesium.Color(1, 1, 0, 0.75),
            //         pixelSize: 50,
            //       },
            //     });
            //   }
            // }
          }
        };

        const saveResults = () => {
          console.log('saving results', intersections);
          const register = [];
          for (let i = 0; i < intersections.length; i++) {
            const intersection = intersections[i];
            const payload = new FormData();
            payload.append('model_intersection_center', intersection.intersection ? JSON.stringify([
              intersection.intersection.x,
              intersection.intersection.y,
              intersection.intersection.z,
            ]) : null );
            this.$store.dispatch('inspections__new/updatePhotoIssues', {
              id: intersection.issue,
              isBackground: true,
              payload,
              onSuccess: () => {
                register.push(intersection);
                if (register.length === intersections.length) {
                  console.log('saving complete');
                  completeAndReset();
                }
              },
            });
          }
        };

        const calculateIntersections = () => {
          console.log('calculateIntersections');
          for (let i = 0; i < issues.length; i++) {
            const issue = issues[i];
            this.$store.commit('cesium/fixIssueIntersectionCenter', {
              photo,
              issue,
              callback: (result) => {
                intersections.push({ issue: issue.id, intersection: result });
                if (intersections.length === issues.length) {
                  saveResults();
                }
              },
            });
          }
        };

        const cameraMovementCompleteHandler = () => {
          if (isLoading) {
            if (tilesets.reduce((acc, curr) => acc & curr.tilesLoaded, true)) {
              isLoading = false;
              calculateIntersections();
            } else {
              console.log('waiting for loading to complete...');
              setTimeout(cameraMovementCompleteHandler, 333);
            }
          } else {
            calculateIntersections();
          }
        };
        isLoading = true;
        this.$store.state.cesium.viewer.camera.setView({
          destination: photo.position,
          orientation: {
            heading: photo.hpr.heading,
            pitch: photo.hpr.pitch,
            roll: photo.hpr.roll,
          },
        });
        setTimeout(cameraMovementCompleteHandler, 1000);
      };

      fixPhoto();
    },
  },
  beforeMount() {
    this.$store.dispatch('cesium/showIssues');
  },
  watch: {
    isReadyTofix: {
      handler(newVal) {
        if (newVal) {
          this.fixModelIntersections();
        }
      },
      immediate: true,
    },
    getInspectionIssuesResult: {
      handler(newVal) {
        try {
          if (!newVal || newVal.length === 0 || newVal[0]?.size === undefined || newVal[0]?.size === undefined) { 
            return;
          }
          let minSize = newVal.find(x => x.size !== undefined && x.size !== null && x.size !== 'null').size || null;
          let maxSize = minSize;
          if (minSize === null) {
            return;
          }
          newVal.forEach(x => {
            if (x.size < minSize) {
              minSize = x.size;
            }
            if (x.size > maxSize) {
              maxSize = x.size;
            }
          });
          minSize = Math.floor(minSize);
          maxSize = Math.ceil(maxSize);
          this.minSize = minSize;
          this.maxSize = maxSize;
          this.minSizeEntered = minSize;
          this.maxSizeEntered = maxSize;
          this.sizesApplied = [minSize, maxSize]; 
        } catch (error) {
          console.log(error)
        }
        
      },
      immediate: true,
    },
    activeIssuesCategories() {
      this.$store.dispatch('cesium/filterIssues', this.itemsFiltered);
    },
    sizesApplied() {
      this.$store.dispatch('cesium/filterIssues', this.itemsFiltered);
    },
    minSizeEntered(newVal) {
      if (this.sizeUpdateTimeout) {
        clearTimeout(this.sizeUpdateTimeout);
      }
      this.sizeUpdateTimeout = setTimeout(() => {
        this.sizesApplied = [parseFloat(newVal), parseFloat(this.maxSizeEntered)];
        clearTimeout(this.sizeUpdateTimeout);
        this.sizeUpdateTimeout = null;
      }, this.sizeUpdateDelay);
    },
    maxSizeEntered(newVal) {
      if (this.sizeUpdateTimeout) {
        clearTimeout(this.sizeUpdateTimeout);
      }
      this.sizeUpdateTimeout = setTimeout(() => {
        this.sizesApplied = [parseFloat(this.minSizeEntered), parseFloat(newVal)];
        clearTimeout(this.sizeUpdateTimeout);
        this.sizeUpdateTimeout = null;
      }, this.sizeUpdateDelay);
    },
  },
}
