
import { mapState } from 'vuex';
import Loader from '@/components/Loader.vue';

export default {
  name: "InspectionsItemMeasurementsPointsEditor",
  components: {
    Loader,
  },
  props: {
    measurement: Object,
  },
  data: () => ({
    newData: null,
    newDescription: null,
    isAutoCancelNeeded: true,
  }),
  computed: {
    ...mapState('measurements', [
      'getMeasurementsResultResult',

      'updateMeasurementsItemResult',
      'updateMeasurementsItemIsLoading',
      'updateMeasurementsItemError',
    ]),
  },
  methods: {
    removePosition(index) {
      const newPositions = this.newData.positions.filter((_, i) => i !== index);
      this.newData = {
        ...this.newData,
        positions: newPositions,
      };
      this.$store.dispatch('cesium/redrawMeasurementEntity', { measurementData: this.newData });
    },
    save() {
      this.isAutoCancelNeeded = false;
      const formData = new FormData();
      formData.append('positions', JSON.stringify(this.newData.positions));
      if (this.newDescription) {
        formData.append('content', this.newDescription);
      }
      this.$store.dispatch('measurements/updateMeasurementsItem', {
        data: formData,
        id: this.measurement.id,
      });
    },
    cancel() {
      this.isAutoCancelNeeded = false;
      this.$store.dispatch('cesium/redrawMeasurementEntity', { measurementData: this.measurement });
      this.$store.commit('cesium/colorizeMeasurementEntity', { measurementData: this.measurement, visualState: 'selected' });
      this.$store.dispatch('cesium/setIsSelectedMeasurementEditable', false);
    },
  },
  beforeMount() {
    this.newData = JSON.parse(JSON.stringify(this.measurement));
    if (this.measurement.content) {
      this.newDescription = this.measurement.content;
    }
  },
  beforeUnmount() {
    if (this.isAutoCancelNeeded) {
      this.$store.dispatch('cesium/redrawMeasurementEntity', { measurementData: this.measurement });
      this.$store.commit('cesium/colorizeMeasurementEntity', { measurementData: this.measurement, visualState: 'default' });
      this.$store.dispatch('cesium/setIsSelectedMeasurementEditable', false);
    }
  },
  watch: {
    updateMeasurementsItemIsLoading(newVal, oldVal) {
      if (oldVal && !newVal && !this.updateMeasurementsItemError) {
        this.$store.dispatch('measurements/getMeasurements', { inspection: this.measurement.inspection });
        this.$store.dispatch('cesium/redrawMeasurementEntity', { measurementData: this.newData });
        this.$store.commit('cesium/colorizeMeasurementEntity', { measurementData: this.newData, visualState: 'selected' });
        this.$store.dispatch('cesium/setIsSelectedMeasurementEditable', false);
      }
    },
  },
}
