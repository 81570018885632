
import { mapState } from 'vuex';
import settings from '@/core/config/settings';

export default {
  name: 'InspectionsItemUploader',
  props: {
    direction: {
      type: String,
      default: 'vertical',
    },
  },
  data: () => ({
    isFPVActive: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',
    ]),
    tools() {
      const result = [
        {
          title: 'Uploads',
          items: [
            {
              title: 'Upload photos',
              code: 'upload-photos',
              icon: 'media/icons/icomoon-free/upload.svg',
              iconSecondary: 'media/icons/duotune/general/gen006.svg',
            },
          ],
        },
        {
          title: 'Photos',
          items: [
            {
              title: 'View all photos',
              code: 'photos',
              icon: 'media/icons/duotune/general/gen006.svg',
              // iconSecondary: 'media/icons/duotune/general/gen006.svg',
            },
          ],
        },
        {
          title: 'Viewer',
          items: [
            {
              title: 'Reset view',
              code: 'reset',
              icon: 'media/icons/icomoon-free/reset.svg',
            },
            {
              title: 'Toggle Globe',
              code: 'toggle-globe',
              icon: 'media/icons/duotune/maps/map004.svg',
            },
          ],
        },
        {
          title: 'Navigation',
          items: [
            {
              title: `FPV: First Person View WASD-navigation mode\n
Use W, A, S, D or arrow keys to navigate
Use Q key to move camera down
Use E key to move camera up
Hold Mouse Left Button and move cursor to rotate camera
Hold Shift key to multiply camera movement speed
              `,
              code: 'fpv',
              icon: 'media/icons/icomoon-free/arrows-navigation.svg',
            },
            {
              title: `Lock / Unlock camera position\nUse SPACE key to toggle`,
              code: 'lock',
              icon: 'media/icons/duotune/general/gen047.svg',
            },
          ],
        },
      ];
      if (settings.bamOrganizationsIds.indexOf(this.currentInspection?.organization) > -1) {
        result[0].items.push({
          title: 'Upload issues',
          code: 'upload-issues',
          icon: 'media/icons/icomoon-free/upload.svg',
          iconSecondary: 'media/icons/icomoon-free/wrench.svg',
        });
      }
      return result;
    },
  },
  methods: {
    toolClickHandler(tool) {
      if (tool.code === 'upload-photos') {
        this.$store.dispatch('modals/show', {
          name: 'UploadPhotos',
          props: {
            inspection: this.currentInspection,
          },
        });
      } else if (tool.code === 'upload-issues') {
        this.$store.dispatch('modals/show', {
          name: 'UploadIssues',
          props: {
            inspection: this.currentInspection,
          },
        });
      } else if (tool.code === 'reset') {
        this.$store.dispatch('cesium/resetView');
      } else if (tool.code === 'toggle-globe') {
        this.$store.dispatch('cesium/toggleGlobe');
      } else if (tool.code === 'fpv') {
        this.isFPVActive = !this.isFPVActive;
      } else if (tool.code === 'lock') {
        this.$store.dispatch('cesium/toggleCameraLock');
      } else if (tool.code === 'photos') {
        this.$store.dispatch('modals/show', {
          name: 'InspectionPhotos',
          props: {
            inspection: this.currentInspection,
          },
        });
      }
    },
  },
  watch: {
    isFPVActive(value) {
      this.$store.dispatch('cesium/setFPV', value);
    },
  }
}
