
import { mapState } from 'vuex';
import InspectionsItemCesium from './ItemCesium';
import Loader from '../Loader';

export default {
  name: "InspectionsItemCompareView",
  components: {
    Loader,
    InspectionsItemCesium,
  },
  data: () => ({
    selectedInspection: null,
    isListOpenedInternal: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'getInspectionsResult',
      'getInspectionsIsLoading',

      'currentInspection',

      'getInspectionsItemIsLoading',
    ]),
    relatedInspections() {
      return this.$store.getters['inspections__new/relatedInspections'];
    },
    isLoading() {
      return this.getInspectionsIsLoading || this.getInspectionsItemIsLoading;
    },
    isEmpty() {
      return !this.isLoading && (this.relatedInspections.length === 0);
    },
    isListOpened() {
      return this.isListOpenedInternal || this.isEmpty || !this.selectedInspection;
    },
  },
  methods: {
    toggleList() {
      this.isListOpenedInternal = !this.isListOpenedInternal;
    },
    setInspection(item) {
      this.isListOpenedInternal = false;
      this.selectedInspection = item;
    },
  },
  mounted() {
    if (!this.getInspectionsResult && !this.getInspectionsIsLoading) {
      this.$store.dispatch('inspections__new/getInspections');
    }
  },
}
