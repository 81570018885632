
export default {
  name: 'InspectionsItemArrows',
  methods: {
    arrowHandler(direction) {
      this.$store.dispatch('cesium/goToNearestCamera', { direction });
    },
  },
  beforeMount() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowUp') {
        this.arrowHandler('up');
      } else if (e.key === 'ArrowLeft') {
        this.arrowHandler('left');
      } else if (e.key === 'ArrowDown') {
        this.arrowHandler('down');
      } else if (e.key === 'ArrowRight') {
        this.arrowHandler('right');
      }
    });
  },
};
