

import Loader from '../Loader'
import { mapState } from 'vuex'

export default {
  name: 'InspectionsItemCesium',
  components: {
    Loader,
  },
  data: () => ({
    isLoading: true,
    isZoomed: false,
  }),
  computed: {
    ...mapState('cesium', [
      'activeCameraEntity',
    ]),
    ...mapState('inspections__new', [
      'getInspectionPhotosResult',
      'getInspectionPhotosIsLoading',
    ]),
    photo() {
      if (!this.activeCameraEntity || !this.getInspectionPhotosResult) {
        return null;
      }
      const currentPhotoId = parseInt(this.activeCameraEntity.id.split('-')[1], 10);
      return this.getInspectionPhotosResult.find(x => x.id === currentPhotoId) || null;
    },
  },
  methods: {
    photoLoadHandler() {
      this.isLoading = false;
    },
    toggleZoom() {
      this.isZoomed = !this.isZoomed;
    },
  },
  watch: {
    photo() {
      this.isLoading = true;
      this.isZoomed = false;
    },
  },
}
