
import { mapState } from 'vuex';
import Loader from '../Loader'

export default {
  name: "InspectionsItemNotesAdd",
  components: {
    Loader,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    content: '',
    urgency: '',
    position: '',
    file1: null,
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    file6: null,
  }),
  computed: {
    ...mapState('cesium', [
      'currentTool',
      'floatingPoint',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionNotesResult',
      'getInspectionNotesIsLoading',
      'getInspectionNotesError',

      'createInspectionNotesItemResult',
      'createInspectionNotesItemIsLoading',
      'createInspectionNotesItemError',

      'editInspectionNotesItemResult',
      'editInspectionNotesItemIsLoading',
      'editInspectionNotesItemError',
    ]),
    isLoading() {
      return this.getInspectionNotesIsLoading ||
        this.createInspectionNotesItemIsLoading ||
        this.editInspectionNotesItemIsLoading ||
        this.getCurrentProfileIsLoading;
    },
    isValid() {
      return this.currentInspection && this.content && this.urgency && this.position;
    },
  },
  methods: {
    addFileHandler(e, i) {
      if (e.target.files.length > 0) {
        this['file' + i] = e.target.files[0];
      } else {
        this['file' + i] = null;
      }
    },
    onSubmit() {
      if (!this.isValid || this.isLoading) {
        return;
      }
      const formData = new FormData();
      formData.append('content', this.content);
      formData.append('urgency', this.urgency);
      formData.append('position', `${this.position.x}, ${this.position.y}, ${this.position.z}`);
      formData.append('inspection', this.currentInspection.id);
      formData.append('user', this.getCurrentProfileResult.user);
      if (this.file1 && typeof this.file1 !== 'string') {
        formData.append('file', this.file1);
      }
      if (this.file2 && typeof this.file2 !== 'string') {
        formData.append('file2', this.file2);
      }
      if (this.file3 && typeof this.file3 !== 'string') {
        formData.append('file3', this.file3);
      }
      if (this.file4 && typeof this.file4 !== 'string') {
        formData.append('file4', this.file4);
      }
      if (this.file5 && typeof this.file5 !== 'string') {
        formData.append('file5', this.file5);
      }
      if (this.file6 && typeof this.file6 !== 'string') {
        formData.append('file6', this.file6);
      }
      const payload = {
        data: formData,
        onSuccess: this.successHandler,
      };
      if (this.item) {
        payload.id = this.item.id;
      }
      this.$store.dispatch(this.item ? 'inspections__new/editInspectionNotesItem' : 'inspections__new/createInspectionNotesItem', payload);
    },
    successHandler(data) {
      this.$store.dispatch('cesium/completeToolAction');
      if (this.item) {
        this.$store.state.inspections__new.getInspectionNotesResult = [ data, ...this.getInspectionNotesResult.filter(x => x.id !== this.item.id) ];
        this.$store.commit('cesium/deleteNote', { id: this.item.id });
        this.$store.commit('cesium/drawNote', data);
        this.back();
      } else {
        this.content = '';
        this.urgency = '';
        this.position = '';
        this.file1 = null;
        this.file2 = null;
        this.file3 = null;
        this.file4 = null;
        this.file5 = null;
        this.file6 = null;
        this.$store.state.inspections__new.getInspectionNotesResult = [ data, ...this.getInspectionNotesResult ];
        this.$store.commit('cesium/drawNote', data);
      }
    },
    setCurrentTool() {
      if (this.$store.state.cesium.currentTool) {
        if (this.$store.state.cesium.currentTool.code === 'measure-height') {
          this.$store.dispatch('cesium/cancelToolAction');
        } else {
          this.$store.dispatch('cesium/completeToolAction');
        }
      }
      this.$store.state.cesium.currentTool = { title: 'Add note', code: 'notes-add', type: 'point', icon: 'media/icons/duotune/general/gen055.svg' };
    },
    back() {
      this.$store.dispatch('cesium/completeToolAction');
      this.$emit('back');
    },
  },
  beforeMount() {
    if (this.item) {
      this.content = this.item.content;
      this.urgency = this.item.urgency;
      this.position = this.item.position.split(',').map(x => parseFloat(x.trim())).reduce((acc, cur, i) => {
        if (i === 0) {
          acc.x = cur;
        } else if (i === 1) {
          acc.y = cur;
        } else if (i === 2) {
          acc.z = cur;
        }
        return acc;
      }, {});
      this.file1 = this.item.file || null;
      this.file2 = this.item.file2 || null;
      this.file3 = this.item.file3 || null;
      this.file4 = this.item.file4 || null;
      this.file5 = this.item.file5 || null;
      this.file6 = this.item.file6 || null;
    }
  },
  mounted() {
    this.setCurrentTool();
  },
  watch: {
    floatingPoint(newVal) {
      if (this.currentTool?.code === 'notes-add') {
        if (newVal) {
          this.position = newVal.position._value;
        } else {
          this.position = '';
        }
      }
    },
  },
}
