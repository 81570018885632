
import { mapState } from 'vuex'
import InspectionsItemCesium from './ItemCesium'
import InspectionsItemCompareView from './ItemCompareView'
import InspectionsItemPhotosList from './ItemPhotosList'
import InspectionsItemPhotoMiniView from './ItemPhotoMiniView'
import InspectionsItemPhotosNearClick from './ItemPhotosNearClick'
import InspectionsItemCameras from './ItemCameras'
import InspectionsItemMeasurements from './ItemMeasurements'
import InspectionsItemNotes from './ItemNotes'
import InspectionsItemIssues from './ItemIssues'
import InspectionsItemToolbar from './ItemToolbar'
import InspectionsItemArrows from './ItemArrows'
import InspectionsItemUploader from './ItemUploader'
import Loader from '../Loader'

export default {
  name: 'InspectionsItem',
  components : {
    InspectionsItemCesium,
    InspectionsItemCompareView,
    InspectionsItemPhotosList,
    InspectionsItemPhotoMiniView,
    InspectionsItemPhotosNearClick,
    InspectionsItemCameras,
    InspectionsItemMeasurements,
    InspectionsItemNotes,
    InspectionsItemIssues,
    InspectionsItemToolbar,
    InspectionsItemArrows,
    InspectionsItemUploader,
    Loader,
  },
  data: () => ({
    isPhotoListVisible: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionsResult',

      'getInspectionsItemResult',
      'getInspectionsItemIsLoading',
      'getInspectionsItemError',

      'getInspectionPhotosResult',
    ]),
    ...mapState('cesium', [
      'currentView',
      'currentMode',
      'currentTool',
      'activeCameraEntity',
      'closestPhotos',
      'isCamerasEntityVisible',
      'intersectionFixesInspectionId',
      'intersectionFixesProgress',
      'intersectionFixesCompleted',
    ]),
    ...mapState('tags', [
      'getTagsResult',
      'getTagsIsLoading',
      'getTagsError',
    ]),
    ...mapState('measurements', [
      'getMeasurementsResult',
      'getMeasurementsIsLoading',
      'getMeasurementsError',
    ]),
    isLoading() {
      return !this.inspection ||
        this.getInspectionsItemIsLoading;
    },
    isMiniViewActive() {
      return this.currentView === 'mini';
    },
    isCompareViewActive() {
      return this.currentView === 'compare';
    },
    isPhotosNearClickSidebarVisible() {
      return this.closestPhotos && !this.activeCameraEntity;
    },
    isSidebarActive() {
      return this.isPhotosNearClickSidebarVisible || this.currentMode !== null;
    },
    inspection() {
      return this.currentInspection;
    },
  },
  watch: {
    currentInspection(newVal) {
      if (newVal) {
        const payload = { id: this.currentInspection.id, isBackground: true };
        this.$store.dispatch('inspections__new/getInspectionPhotos', { id: this.currentInspection.id });
        this.$store.dispatch('inspections__new/getInspectionIssues', payload);
        this.$store.dispatch('inspections__new/getInspectionNotes', payload);
        this.$store.dispatch('measurements/getMeasurements', { inspection: this.currentInspection.id });
        if (!this.getTagsResult) {
          this.$store.dispatch('tags/getTags');
        }
      }
    },
    currentView() {
      this.$store.dispatch('cesium/cancelToolAction');
      this.$store.state.cesium.currentTool = null;
    },
    currentMode(newVal, oldVal) {
      this.$store.dispatch('cesium/cancelToolAction');
      this.$store.state.cesium.currentTool = null;
      if (newVal !== 'measurements' && oldVal === 'measurements') {
        this.$store.dispatch('cesium/hideMeasurements');
      }
    },
  },
}
