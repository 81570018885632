

import * as Cesium from 'cesium'
import "cesium/Build/Cesium/Widgets/widgets.css"
import CesiumFunctions from '../../core/cesium/CesiumFunctions'
import Loader from '../Loader'
import { mapGetters, mapState } from 'vuex'
import settings from '@/core/config/settings';
import { toRaw } from 'vue'
import { ready } from 'jquery'

export default {
  name: 'InspectionsItemCesium',
  props: {
    isCompareView: {
      type: Boolean,
      default: false,
    },
    givenInspection: {
      type: Object,
      default: null,
    },
  },
  components: {
    Loader,
  },
  data () {
    return {
      isLoadingInternal: true,
      handler: null,
      isZoomToCameraNeeded: false,
    }
  },
  computed: {
    ...mapState('cesium', [
      'activeShape',
      'activeShapePoints',
      'currentView',
      'currentMode',
      'currentTool',
      'floatingPoint',
      'viewer',
      'lastActiveIssueId',
      'lastActiveIssueBillboard',
      'isCamerasEntityVisible',
      'isIssuesEntityVisible',
      'isMeasurementsEntityVisible',
      'isSelectedMeasurementEditable',
      'isNotesEntityVisible',
      'damagedIssue',
      'cameraTarget',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',

      'getInspectionPhotosResult',
      'getInspectionPhotosIsLoading',
      'getInspectionPhotosError',

      'getInspectionPhotosIsLoading',
    ]),
    ...mapGetters('inspections__new', [
      'photosWithCesiumData',
    ]),
    noCesiumData() {
      return this.$store.getters['inspections__new/noCesiumData'];
    },
    inspection() {
      return (this.isCompareView && this.givenInspection) ? this.givenInspection : this.currentInspection;
    },
    isLoading() {
      return this.isLoadingInternal;
    },
    containerId() {
      return this.isCompareView ? 'cesiumCompareContainer' : 'cesiumContainer';
    },
    isZoomToCameraReady() {
      return this.isZoomToCameraNeeded && this.viewer && this.getInspectionPhotosResult?.length > 0;
    },
  },
  methods: {
    initCesium() {
      Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMjUwYWI3My05MTk3LTQyNDktYmRmMS00MTU2MGUxYmI3ZDIiLCJpZCI6MjU5MjgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1ODY4NzA4OTJ9.6WPWSe0-SU4dzAHEaJPpwbFFr-wj8PsZ0JS3EA0mUQg';
      Cesium.RequestScheduler.maximumRequests = 6; // Adjust this value as needed
      Cesium.RequestScheduler.maximumRequestsPerServer = 6; // Adjust this value as needed
      const options = {
        terrainProvider: Cesium.createWorldTerrain({
          requestVertexNormals : true,
        }),
        geocoder: false,
        sceneModePicker: false,
        navigationHelpButton: false,
        animation: false,
        creditsDisplay: false,
        timeline: false,
        infoBox: false,
        homeButton: false,
        fullscreenButton: false,
        // baseLayer: Cesium.ImageryLayer.fromWorldImagery({
        //   style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
        // }),
        baseLayerPicker: false,
        selectionIndicator: false,
      };
      var viewer = new Cesium.Viewer(this.containerId, options);

      if (settings.organizationsWitNoGlobe.indexOf(this.inspection.organization) > -1) {
        viewer.scene.globe.show = false;
        viewer.scene.skyAtmosphere.show = false;
      }

      var assetIDs = this.inspection.cesium_id?.replaceAll(' ', '').split(',') || [];
      var terrainIDs = this.inspection.cesium_terrain_id?.replaceAll(' ', '').split(',') || [];
      const tilesets = [];
      const loadedTilesetsRegister = [];
      let failedCounter = 0;
      let successCounter = 0;
      const maxCounter = terrainIDs.length + assetIDs.length;
      for (var i = 0; i < assetIDs.length; i++) {
        const request = Cesium.IonResource.fromAssetId(assetIDs[i]);
        request.catch(_ => {
          failedCounter++;
          if (successCounter + failedCounter === maxCounter) {
            this.isLoadingInternal = false;
            this.isZoomToCameraNeeded = true;
          }
        });
        // const tileset = new Cesium.Cesium3DTileset({
        //   url: request,
        //   maximumScreenSpaceError: this.inspection.cesium_maxError,
        //   maximumMemoryUsage: 2048,
        //   dynamicScreenSpaceError: true,
        //   skipLevelOfDetail: false,
        //   foveatedScreenSpaceError: true,
        //   skipLevels: 2,
        //   skipScreenSpaceErrorFactor: 1,
        //   immediatelyLoadDesiredLevelOfDetail: false,
        //   baseScreenSpaceError: 1024,
        //   loadSiblings: false,
        // });
         const tileset = new Cesium.Cesium3DTileset({
          url: request,
          maximumScreenSpaceError: this.inspection.cesium_maxError, // Consider increasing this value to reduce detail
          maximumMemoryUsage: 1024, // Lower memory usage
          dynamicScreenSpaceError: true,
          skipLevelOfDetail: true, // Enable skipping levels of detail for better performance
          foveatedScreenSpaceError: false, // Disable foveated rendering to reduce complexity
          skipLevels: 1, // Reduce the number of levels skipped to decrease detail
          skipScreenSpaceErrorFactor: 2, // Increase this to allow more aggressive level skipping
          immediatelyLoadDesiredLevelOfDetail: false,
          baseScreenSpaceError: 1024,
          loadSiblings: false,
          // enableDebugWireframe: true,
          // debugWireframe: true,
          // preferLeaves: true,
        });
        tileset.allTilesLoaded.addEventListener(() => {
          loadedTilesetsRegister.push(true);
          if (loadedTilesetsRegister.length === assetIDs.length) {
            this.$store.state.cesium.isTilesetLoaded = true;
          }
        });
        tileset.loadProgress.addEventListener((numberOfPendingRequests, numberOfTilesProcessing) => {
          if ((numberOfPendingRequests === 0) && (numberOfTilesProcessing === 0)) {
            if (this.inspection.adjust_cameras) {
              const cartographic = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
              const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
              const offset = Cesium.Cartesian3.fromRadians(
                cartographic.longitude + parseFloat(this.inspection.adjust_longitude || 0),
                cartographic.latitude + parseFloat(this.inspection.adjust_latitude || 0),
                parseFloat(this.inspection.adjust_altitude || 0),
              );
              const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
              tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
            }
            if (i === (assetIDs.length)) {
              this.isLoadingInternal = false;
            }
            return;
          }
        });
        tilesets.push(tileset);
        viewer.scene.primitives.add(tileset);
      }
      for (var i = 0; i < terrainIDs.length; i++) {
        const provider = new Cesium.IonImageryProvider({ assetId: parseInt(terrainIDs[i], 10), accessToken: Cesium.Ion.defaultAccessToken });
        this.$store.state.cesium.imageryLayerAlpha = 0.5;
        this.$store.state.cesium.imageryLayer = new Cesium.ImageryLayer(provider, { alpha: this.$store.state.cesium.imageryLayerAlpha });
        viewer.scene.imageryLayers.add(this.$store.state.cesium.imageryLayer);
        terrains.push(this.$store.state.cesium.imageryLayer);
        successCounter++;
        if (successCounter + failedCounter === maxCounter) {
          this.isLoadingInternal = false;
        }
      }
      const timeout = setTimeout(() => {
        this.isLoadingInternal = false;
        clearTimeout(timeout);
      }, 3000);

      viewer.camera.defaultZoomAmount = 10.0;
      viewer.camera.frustum.fov = 1;
      if (this.isCompareView) {
        viewer.camera.percentageChanged = 0.01;

        viewer.scene.screenSpaceCameraController.enableRotate = false;
        viewer.scene.screenSpaceCameraController.enableTranslate = false;
        viewer.scene.screenSpaceCameraController.enableZoom = false;
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.screenSpaceCameraController.enableLook = false;
      }

      if (this.isCompareView) {
        viewer.scene.camera = this.viewer.scene.camera;
        this.$store.state.cesium.viewerCompare = viewer;
      } else {
        this.$store.state.cesium.viewer = viewer;
        this.initHandler();
      }

      if (!this.isZoomToCameraNeeded) {
        if (tilesets.length > 0) {
          viewer.zoomTo(tilesets[tilesets.length - 1]).then(() => {
          this.$store.state.cesium.initialTileset = tilesets[tilesets.length - 1];
          });
        } else if (terrains.length > 0) {
          viewer.zoomTo(terrains[terrains.length - 1]);
        }
      }
    },
    spaceHandler(e) {
      if (e.code === 'Space') {
        this.$store.dispatch('cesium/toggleCameraLock');
      }
    },
    doubleClickHandler(position) {
      let objectClicked = this.viewer.scene.pick(position);
        if (!Cesium.defined(objectClicked)  || !objectClicked?.content?._model?.boundingSphere?.center) {
          return;
        }
        this.$store.state.cesium.cameraTarget = {
          position: objectClicked.content._model.boundingSphere.center,
          tile: objectClicked?.content?._model,
        };
    },
    initHandler() {
      window.document.addEventListener("keydown", this.spaceHandler);

      const isTouchScreen = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      if (isTouchScreen) {
        let lastClickDate = new Date();
        const dblclickDuration = 333;
        this.viewer.canvas.addEventListener("click", (e) => {
          if (new Date() - lastClickDate <= dblclickDuration) {
            this.doubleClickHandler({ x: e.offsetX, y: e.offsetY });
          }
          lastClickDate = new Date();
        });
      }
      this.handler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas);

      // Double Left Click
      this.handler.setInputAction(event => {
        this.doubleClickHandler(event.position);
      }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

      // left click
      this.handler.setInputAction(event => {
        if (this.damagedIssue) {
          let position = this.viewer.scene.pickPosition(event.position);
          if (Cesium.defined(position)) {
            this.$store.state.cesium.damagedIssuePosition = position;
          }
          return;
        }
        if (!this.currentTool) {
          // Show Photo from Camera
          if (this.isCamerasEntityVisible) {
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'camera-';
            if (Cesium.defined(objectClicked) && objectClicked?.id?.startsWith?.(prefix)) {
              const photoId = parseInt(objectClicked.id.substring(prefix.length), 10);
              this.$store.state.cesium.closestPhotos = null;
              this.$store.state.cesium.activeCameraEntity = objectClicked.primitive;
              this.$store.state.cesium.currentMode = 'cameras';
              this.$store.dispatch('cesium/flyToCamera', { photoId });
              return;
            }
          }
          // Fly to clicked note
          if (this.isNotesEntityVisible) {
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'note-';
            if (Cesium.defined(objectClicked) && objectClicked.id?.id?.startsWith?.(prefix)) {
              this.$store.dispatch('cesium/toggleNoteItem', objectClicked.id);
            }
            return;
          }
          if (this.isMeasurementsEntityVisible) {
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'measure-';
            if (Cesium.defined(objectClicked) && objectClicked.id?.id?.startsWith?.(prefix)) {
                this.$store.dispatch('cesium/toggleMeasurementItem', objectClicked.id);
            }
            return;
          }
          if (this.isIssuesEntityVisible) {
            // Fly to clicked issue parent
            let objectClicked = this.viewer.scene.pick(event.position);
            const prefix = 'issue-';
            if (Cesium.defined(objectClicked) && objectClicked?.id?.startsWith?.(prefix)) {
              const id = parseInt(objectClicked.id.substring(prefix.length), 10);
              if (this.lastActiveIssueId === id) {
                this.$store.commit('cesium/resetLastIssue');
              } else {
                this.$store.state.cesium.lastActiveIssueId = id;
                this.$store.dispatch('cesium/cameraFlyToIssue', { id });
              }
            }
            return;
          }
          // let position = this.viewer.scene.pickPosition(event.position);
          // if (Cesium.defined(position)) {
          //   this.$store.state.cesium.cameraTarget = { position, tile: null };
          // }
          return;
        }

        // Measuring modes
        if (this.currentTool?.code.indexOf('measure-') > -1) {
          this.$store.commit('cesium/setSelectedMeasurementId', null);
          const position = this.viewer.scene.pickPosition(event.position);
          if (Cesium.defined(position)) {
            let points = [ ...this.$store.state.cesium.activeShapePoints ];
            if (points.length === 0) {
              this.$store.state.cesium.floatingPoint = CesiumFunctions.drawPoint({ viewer: this.viewer, position });
              points.push(position);
              var dynamicPositions = new Cesium.CallbackProperty(() => {
                if (this.currentTool.type === 'polygon') {
                  return new Cesium.PolygonHierarchy(this.$store.state.cesium.activeShapePoints);
                }
                return this.$store.state.cesium.activeShapePoints;
              }, false);
              this.$store.state.cesium.activeShape = CesiumFunctions.drawShape({
                viewer: this.viewer,
                positions: dynamicPositions,
                type: this.currentTool.type,
                isDraft: true,
              });
            }
            points.push(position);
            this.$store.state.cesium.activeShapePoints = points;
            this.$store.state.cesium.activeShapePointsGeometry = [
              ...this.$store.state.cesium.activeShapePointsGeometry,
              CesiumFunctions.drawPoint({ viewer: this.viewer, position }),
            ];
            if (this.currentTool.type === 'height' && points.length >= 3) {
              this.$store.dispatch('cesium/completeToolAction');
            }
          }
          return;
        }

        // Add note
        if (this.currentTool.code === 'notes-add') {
          let position = this.viewer.scene.pickPosition(event.position);
          if (Cesium.defined(position)) {
            if (this.floatingPoint) {
              this.viewer.entities.remove(this.floatingPoint);
            }
            this.$store.state.cesium.floatingPoint = CesiumFunctions.drawPoint({ viewer: this.viewer, position });
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // mouse move
      this.handler.setInputAction(event => {
        if (!this.currentTool) {
          return;
        }

        // Measuring modes
        if (this.currentTool.code.indexOf('measure-') > -1) {
          if (Cesium.defined(this.floatingPoint)) {
            var newPosition = this.viewer.scene.pickPosition(event.endPosition);
            if (Cesium.defined(newPosition)) {
              this.floatingPoint.position.setValue(newPosition);
              this.$store.state.cesium.activeShapePoints = [
                ...this.$store.state.cesium.activeShapePoints.slice(0, this.$store.state.cesium.activeShapePoints.length - 1),
                newPosition,
              ];
            }
          }
        }

      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // right click
      this.handler.setInputAction(event => {
        if (!this.currentTool) {
          const position = this.viewer.scene.pickPosition(event.position);

          if (!Cesium.defined(position)) {
            return;
          }

          this.$store.state.cesium.currentMode = null;
          this.$store.state.cesium.activeCameraEntity = null;
          this.$store.dispatch('cesium/getClosestPhotos', { position });

          return;
        }
        if (this.currentTool.type === 'height' && this.activeShapePoints.length <= 2) {
          this.$store.dispatch('cesium/cancelToolAction');
          return;
        }
        this.$store.dispatch('cesium/completeToolAction');
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
  },
  mounted() {
    if (this.noCesiumData) {
      this.isLoadingInternal = false;
      !this.isCompareView && this.$emit('show-photos');
      return;
    }
    this.initCesium();
  },
  beforeUnmount() {
    window.document.removeEventListener("keydown", this.spaceHandler);
    this.$store.state.cesium.isTilesetLoaded = false;
  },
  watch: {
    currentInspection() {
      this.$store.state.cesium.isTilesetLoaded = false;
    },
    isZoomToCameraReady() {
      if (this.isZoomToCameraReady) {
        this.$store.state.cesium.currentMode = 'cameras';
        this.$store.dispatch('cesium/toggleVisibility', 'cameras');
        this.$store.dispatch('cesium/flyToCamera', { photoId: this.getInspectionPhotosResult[0].id });
      }
    },
    cameraTarget(newVal, oldVal) {
      this.$store.dispatch('cesium/updateCameraTarget', { newTarget: newVal, oldTarget: oldVal });
    },
  },
}
