
import { mapGetters, mapState } from 'vuex';
import * as Cesium from 'cesium';
import InspectionsItemMeasurementsDrafts from './ItemMeasurementsDrafts';
import InspectionsItemMeasurementsUploaded from './ItemMeasurementsUploaded';
import Loader from '../Loader'

export default {
  name: "InspectionsItemMeasurements",
  components: {
    InspectionsItemMeasurementsUploaded,
    InspectionsItemMeasurementsDrafts,
    Loader,
  },
  data: () => ({
    cameraData: null,
    tools: [
      { title: 'Distance', code: 'measure-line', type: 'line', icon: 'media/icons/duotune/art/art005.svg' },
      { title: 'Area', code: 'measure-area', type: 'polygon', icon: 'media/icons/duotune/art/art006.svg' },
      { title: 'Height', code: 'measure-height', type: 'height', icon: 'media/icons/duotune/graphs/gra002.svg' },
    ],
  }),
  computed: {
    ...mapState('users', [
      'getUsersResult',
      'getUsersIsLoading',
    ]),
    ...mapState('tags', [
      'getTagsIsLoading',
    ]),
    ...mapGetters('cesium', [
      'isToolActionCompletable',
    ]),
    ...mapState('cesium', [
      'viewer',
      'currentTool',
      'activeShapePoints',
    ]),
    ...mapState('measurements', [
      'defaultTagId',

      'getMeasurementsResult',
      'getMeasurementsIsLoading',
      'getMeasurementsError',

      'removeMeasurementsItemResult',
      'removeMeasurementsItemIsLoading',
      'removeMeasurementsItemError',
    ]),
    isLoading() {
      return this.getMeasurementsIsLoading || this.getTagsIsLoading || this.removeMeasurementsItemIsLoading || this.getUsersIsLoading;
    },
  },
  methods: {
    updateCameraData() {
      const cameraPosition = new Cesium.Cartographic.fromCartesian(this.viewer.camera.position);
      this.cameraData = {
        latitude: (cameraPosition.latitude * Cesium.Math.DEGREES_PER_RADIAN).toFixed(6),
        longitude: (cameraPosition.longitude * Cesium.Math.DEGREES_PER_RADIAN).toFixed(6),
        altitude: (cameraPosition.height).toFixed(2),
        pitch: (this.viewer.camera.pitch * 180 / Math.PI).toFixed(1),
        heading: (this.viewer.camera.heading * 180 / Math.PI).toFixed(1),
      };
    },
    setCurrentTool(tool) {
      if (this.$store.state.cesium.currentTool) {
        this.$store.dispatch('cesium/completeToolAction');
      }
      if (tool === null) {
        this.$store.state.cesium.currentTool = null;
        return;
      }
      this.$store.state.cesium.currentTool = this.$store.state.cesium.currentTool?.code === tool.code ? null : tool;
    },
    completeMeasurement() {
      this.$store.dispatch('cesium/completeToolAction');
    },
    cancelMeasurement() {
      this.$store.dispatch('cesium/cancelToolAction');
    },
  },
  beforeMount() {
    if (!this.getUsersResult && !this.getUsersIsLoading) {
      this.$store.dispatch('users/getUsers');
    }
    this.viewer.camera.percentageChanged = 0.05;
    this.viewer.camera.changed.addEventListener(this.updateCameraData);
    this.updateCameraData();
    this.$store.dispatch('cesium/showMeasurements');
  },
  beforeUnmount() {
    this.viewer.camera.changed.removeEventListener(this.updateCameraData);
  },
}
